<template>
<div class="c-app flex-row align-items-center">
  <CContainer>
    <CRow class="justify-content-center">
      <CCol md="5">
        <CCardGroup>
          <CCard class="p-4" style="background: #313D54; min-height: 230px">
            <CCardBody>
              <center>
                <img src="/teltech-logo.png" width="228" height="53">
                <div style="height: 40px"></div>
                <div ref="googleLoginBtn"> </div>
              </center>
            </CCardBody>
          </CCard>
        </CCardGroup>

        <div v-if="errorMessage" style="margin-top: 20px">
          <div class="alert alert-danger" role="alert">{{ errorMessage }}</div>
        </div>
      </CCol>
    </CRow>
  </CContainer>
</div>
</template>

<script>
export default {
  name: 'login',
  data() {
    return {
      errorMessage: null,
    }
  },
  methods: {
    handleLogin(credentials) {
      const id_token = credentials.credential;
      this.errorMessage = null;
      var that = this;
      RobocallsAI.shared().loginWithGoogleToken(id_token, {
        onSuccess: function() {
          that.$router.replace("/logged-in");
        },
        onCancelled: function() {
          console.log('Cancelled')
        },
        onFailure: function(error) {
          that.errorMessage = error;
        }
      });
    }
  },
  mounted() {
    window.google.accounts.id.initialize({
      client_id: "166607073303-e83l0lsics5247vtra2c6rec5skgds5r.apps.googleusercontent.com",
      callback: this.handleLogin,
      auto_select: true
    })
    window.google.accounts.id.renderButton(
        this.$refs.googleLoginBtn, {
          text: 'signin_with', // or 'signup_with' | 'continue_with' | 'signin'
          size: 'large', // or 'small' | 'medium'
          width: '366', // max width 400
          theme: 'outline', // or 'filled_black' |  'filled_blue'
          logo_alignment: 'left' // or 'center'
        }
    )
  }

}
</script>
